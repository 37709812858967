import React from "react";
import StoreIcon from '@mui/icons-material/Store';
import {Button} from "@mui/material";

export class Item extends React.Component {
    render() {
        return (
            <div className="item">
                <img src={this.props.item.img} alt={this.props.item.title} onClick={() => this.props.onShowItem(this.props.item)} />
                <h2>{this.props.item.title}</h2>
                <p>Нажмите на иконку, чтобы открыть характеристики/описание</p>
                <b>{this.props.item.price}₽/мес</b>
                <Button className="buyButton"
                    startIcon={<StoreIcon />}
                    variant="contained"
                    color="success"
                    href={this.props.item.link}
                    >Заказать</Button>
            </div>
        )
    }
}

export default Item