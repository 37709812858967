export default function Header(){
    return (
        <footer>
            Unknown Hosting Lab&copy;<br/>
            <ul className="nav">
                <li onClick={() => window.location.replace('oferta.pdf')}>Договор-оферта</li>
                <li onClick={() => window.location.replace('privacy.pdf')}>Политика в отношении обработки персональных данных</li>
                <li onClick={() => window.location.replace('https://status.unknownhostinglab.ru')}>Статус серверов</li>
            </ul>
        </footer>
    )
}