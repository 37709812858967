import React from "react";
import {Button} from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";

export class ShowFullItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [
                {
                    key: 'all',
                    name: 'Всё'
                },
                {
                    key: 'vds',
                    name: 'VDS'
                },
                {
                    key: 'vhost',
                    name: 'Хостинг'
                },
            ]
        }
    }
    handleClick = (event) => {
        if (event.target.className === 'no-target') {
            return
        }
        this.props.onShowItem(this.props.item)
    };
    render() {
        return (
            <div className="modal" onClick={this.handleClick}>
                <div className="no-target">
                    <img src={this.props.item.img} alt={this.props.item.title} onClick={() => this.props.onShowItem(this.props.item)} />
                    <h2 className="no-target">{this.props.item.title}</h2>
                    <p className="no-target">{`${this.props.item.description}`}</p>
                    <b className="no-target">{this.props.item.price}₽</b>
                    <Button className="buyButton"
                            startIcon={<StoreIcon />}
                            variant="contained"
                            color="success"
                            href={this.props.item.link}
                    >Заказать</Button>
                </div>
            </div>
        )
    }
}

export default ShowFullItem