import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Items from "./components/Items";
import Categories from "./components/Categories";
import ShowFullItem from "./components/ShowFullItem";
import axios from "axios";

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentItems: [],
            items: [],
            showFullItem: false,
            fullItem: {}
        }
        this.state.currentItems = this.state.items
        this.chooseCategory = this.chooseCategory.bind(this)
        this.onShowItem = this.onShowItem.bind(this)
        this.setItems = this.setItems.bind(this)
    }
    componentDidMount() {
        const apiUrl = 'https://raw.githubusercontent.com/KirillKochurov/uhl-services/main/services.json';
        axios.get(apiUrl).then((resp) => {
            const allPersons = resp.data;
            this.setItems(allPersons)
        });
    }
    render() {
        return (
            <div className="wrapper">
                <Header />
                <Categories chooseCategory={this.chooseCategory} />
                <Items onShowItem={this.onShowItem} items={this.state.currentItems} />

                {this.state.showFullItem && <ShowFullItem onShowItem={this.onShowItem} item={this.state.fullItem} />}
                <Footer />
            </div>
        )
    }

    setItems(items) {
        this.setState({items: items})
        this.setState({currentItems: items})
    }

    onShowItem(item) {
        this.setState({fullItem: item})
        this.setState({showFullItem: !this.state.showFullItem})
    }

    chooseCategory(category) {
        if (category === 'all') {
            this.setState({currentItems: this.state.items})
            return
        }

        this.setState({
            currentItems: this.state.items.filter(el => el.category === category)
        })
    }
}

export default App;
