export default function Header(){
    return (
        <header>
            <div>
                <span className="logo">Unknown Hosting Lab</span>
                <ul className="nav">
                    <li onClick={() => window.location.replace('oferta.pdf')}>Договор-оферта</li>
                    <li onClick={() => window.location.replace('https://bill.unknownhostinglab.ru/')}>Панель управления</li>
                </ul>
            </div>
            <div className="presentation"></div>
        </header>
    )
}