import React from "react";

export class Categories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [
                {
                    key: 'all',
                    name: 'Всё'
                },
                {
                    key: 'vds',
                    name: 'VDS'
                },
                {
                    key: 'vhost',
                    name: 'Хостинг'
                },
            ]
        }
    }

    render() {
        return (
            <div className="categories">
                {this.state.categories.map(el => (
                    <div key={el.key} onClick={() => this.props.chooseCategory(el.key)}>{el.name}</div>
                ))}
            </div>
        )
    }
}

export default Categories